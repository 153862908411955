import HowToUseNavbar from "./HowToUse/HowToUseNavbar";
import HowToUseMain from "./HowToUse/HowToUseMain";

const HowToUse = () => {
  return (
    <>
      <div className="HowToUseNavbar">
        <HowToUseNavbar />
      </div>
      <div className="HowToUseMain">
        <HowToUseMain />
      </div>
    </>
  )
}
export default HowToUse
