import { useAnimate } from "framer-motion"

const SampleAnimate = () => {
  const [ scope, animate ] = useAnimate()
  const handleAnimate = async() => {
    alert('hello world')
  }

  return (
    <div>
      <div ref={scope}>
        <div id='target' className="h-24 w-24 modalwindow container" />
        <button className="mt-4 rounded-md modalanimatebutton px-4 py-2" onClick={() => handleAnimate()}>
          Trigger animation
        </button>
      </div>
    </div>
  )
}
export default SampleAnimate
