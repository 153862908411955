import { Routes, Route, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

import SettingItems from "./SettingItems";
import SettingDirection from "./SettingDirection";

import './Settings.css';

const SettingsMain = () => {
  const location = useLocation();

  return (
    <>
      <AnimatePresence>
        <Routes location={location} key={Location.pathname}>
          <Route element={<SettingItems />} exact path="/" />
          <Route element={<SettingItems />} path="/settingitems" />
          <Route element={<SettingDirection />} path="/settingdirection" />
        </Routes>
      </AnimatePresence>
    </>
  )
}
export default SettingsMain
