import { NavLink } from 'react-router-dom'
import { useState } from "react";
import styled from "@emotion/styled";

const StyledButton = styled.button`
  color: ${({ active }) => active ? '#ff99ff' : 'lightgray'};
  background: ${({ active }) => active ? 'linear-gradient(180deg, #660033, #9900cc)' : 'linear-gradient(180deg, #100c08, #660033)'};
  ${({ active }) => active && `text-shadow: lightgray 5px 5px 10px; box-shadow: #ff99ff 5px 5px 10px;`}
`;

// navsの最初の''はホームを表す
const navs = ['', 'SampleAnimate', 'SampleAbout', 'SampleCountdown', 'SampleContact', 'SampleSlot', 'SampleScroll', ];
const navsJ = ['サンプルホーム', 'サンプルアニメ', 'SampleAbout', 'カウントダウン', 'Sample連絡', 'Sampleスロット', 'サンプルスク', ];

const SampleNavbar = () => {

  const [active, setActive] = useState(navs[0]);

  return (
    <>
      {navs.map((nav, index) => {
        return (
          <span key={index}>
            <NavLink to={nav}>
              <StyledButton key={nav}
                active={active === nav ? +true : +false }
                onClick={() => setActive(nav)}
              >{navsJ[index]}</StyledButton>
            </NavLink>
          </span>
        )}
      )}
    </>
  )
}

export default SampleNavbar
