import { motion, useAnimationControls, animate, useMotionValue, useTransform, } from "framer-motion";
import { useEffect, useState, } from "react";
import useSound from "use-sound";

import inRaffle from '../../assets/sounds/inRaffle.mp3';
import stop from '../../assets/sounds/stop.mp3';

const Cat1Start4 = () => {

  const [playInRaffle] = useSound(inRaffle);
  const [playStop] = useSound(stop);

  const divControls = useAnimationControls();
  const [ selectedNumber, setSelectedNumber ] = useState(0);
  const [ displayNumber, setDisplayNumber ] = useState(0);
  const [ nowInRaffle, setNowInRaffle ] = useState(false);

  const count = useMotionValue(0);
  const rounded = useTransform(count, Math.round);

  // 1からtargetCountまでの数字を抽選
  let targetCount = 10;
  // ルーレット回転数
  let roundCount = 2;
  // 止まるまでの時間
  let stopTimer = 2;

  const randomNumberInRange = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  const handleRouletteStart = async() => {

    // 抽選中のため、ボタンを無効にする
    setNowInRaffle(true);

    // 0からスタート
    count.set(0);
    await setSelectedNumber(0);

    // 1～Xの数字をランダムでget
    // 「targetCount * x + 1」は数字がx周後に止まる
    // setSelectedNumber(randomNumberInRange(201, 300));
    setSelectedNumber(randomNumberInRange(targetCount * roundCount + 1, targetCount * (roundCount + 1)));
  }

  const modSelectedNumber = selectedNumber % targetCount + 1;

  animate(count, selectedNumber, {
    duration: stopTimer,
    onUpdate: latest => {
      // console.log(
      //   ' selectedNumber:', selectedNumber, ' rounded:', rounded.get(), ' count:', count.get(),
      //   ' nowInRaffle:', nowInRaffle,
      //   ' latest:', latest );
      // loopCounter += 1;
      setDisplayNumber(rounded.get());
    }
  });

  useEffect(() => {
    divControls.start({
      initial: {x:'50%', y:'50%'},
      background: ['linear-gradient(180deg, #000, #000000)', 'linear-gradient(180deg, #000000, #000)'],
      border: ['5px solid #ffaa00', '5px solid #000'],
      color: ['#ffaa00', '#62e616'],
      // width: 300,
      // alignItems: 'center',
      // fontSize: "100px",
      transition: {
        duration: 0.2,
      }
    });

    playInRaffle();

    // 数字が変わるタイミングでログ出力
    // console.log(
    //   ' selectedNumber:', selectedNumber,
    //   ' rounded:', rounded.get(),
    // );

    // 抽選数字になれば、決定音を鳴らして背景色を変更する
    if(selectedNumber === rounded.get()) {
      playStop();
      divControls.start({
        background: 'linear-gradient(180deg, #000000, #000)',
        border: '5px solid #ffff00',
        color: ['#62e616', '#ffff00'],
        scale: [1.0, 1.0],
        transition: {
          duration: 0.5,
        },
      });
      // 抽選が終ったため、ボタンを有効にする
      setNowInRaffle(false);
    }
  }, [displayNumber]);

  return (
    <>
      <div className="container">
        <button className="container" disabled={nowInRaffle} onClick={() => handleRouletteStart()}>
          抽選スタート
        </button>
        <motion.h1
          animate={divControls}
          onAnimationComplete={
            async definition => {
              console.log('Completed animating', definition);
            }
          }
        >
          { rounded.get() % targetCount + 1 }
        </motion.h1>
        <motion.h1 className='home container'>
            { count }
        </motion.h1>
        { modSelectedNumber }
      </div>
    </>
  )
}
export default Cat1Start4
