import { motion, useMotionValue, useTransform, useAnimate, stagger } from "framer-motion";
import { useRef, useState, useEffect } from "react";

// import './SampleCountdown.css';

const SampleCountdown = () => {

  const [ scope, animate ] = useAnimate()
  const [ displayScope, displayAnimate ] = useAnimate()

  const [isAnimating, setIsAnimating] = useState(false)

  const handleRouletteStart = async() => {
    // ボタンを押された感じ
    animate([
      ["button", {scale: 0.8}, {duration: 0.1, at: "<"}],
      ["button", {scale: 1}, {duration: 0.1}],
    ])
    setIsAnimating(!isAnimating)

    // displayAnimate("li", { backgroundColor: isAnimating ? "black" : "white" }, { ease: "backIn" }, { duration: 1 })
    // alert('aaa');
    // selectedNumber = 1;
    selectedNumber = randomNumberInRange(1, 100);
    console.log('reload selectedNumber:', selectedNumber);
  }

  const count = useMotionValue(0);

  const ref = useRef(null);
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);

  let selectedNumber = 0;
  const rounded = useTransform(count, Math.round);

  const randomNumberInRange = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  useEffect(() => {
    // setHeight(ref.current.offsetHeight);
    // setWidth(ref.current.offsetWidth);
    const animation = animate(count, selectedNumber = randomNumberInRange(1, 100), {
      duration: 3,
      onUpdate: latest => console.log(' selectedNumber:', selectedNumber, ' rounded:', rounded, ' count:', count.get(), ' latest:', latest, ),
    });

    return animation.stop;
  }, [selectedNumber]);

  return (
    <>
      <div ref={scope}>
        <div id='target' />
          <button className="mt-4 rounded-md rouletteStartButton px-4 py-2" onClick={() => handleRouletteStart()}>
          {/* <button className="mt-4 rounded-md rouletteStartButton px-4 py-2" onClick={() => setIsAnimating(!isAnimating)}> */}
            ルーレットスタート
          </button>
        </div>
      <div>
        <motion.h1 className='home container'>
          { rounded }
        </motion.h1>
      </div>
      <div ref={displayScope}>
        <div id="campus">
          <div className="box-container">
            <motion.div
              className="samplecountdown"
              initial={{
                opacity: 0.2,
              }}
              animate={{
                // x: isAnimating ? "50rem" : 0,
                // opacity: isAnimating ? 1 : 0.5,
                // rotate: isAnimating ? 360: 0,
                // backgroundColor: "blue",
                y: isAnimating ? "20rem" : 0,
                opacity: isAnimating ? 1 : 0.5,
                backgroundColor: isAnimating ? "lime": "blue",
              }}
              transition={{
                type: "spring",
                // stiffness: 60,
                // damping: 10,
                // duration: 10,
              }}
              // ■をクリックしたら反応させたい場合は下記onclickを有効にする
              // onClick={() => setIsAnimating(!isAnimating)}
            >samplecountdownサンプル
            <li>aaaaaa</li>
            </motion.div>
          </div>
        </div>
      </div>
    </>
  )
}
export default SampleCountdown
