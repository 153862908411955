import { motion, useAnimationControls } from "framer-motion";
import { useEffect, useState } from "react";
import AboutRouletteCanvas from "./AboutRouletteCanvas";

const AboutScroll4 = () => {

  // false:初期状態では非表示
  const [display, setDisplay] = useState(false);

  return (
    <>
      <button
        onClick={(event) => {
          // event.currentTarget.disabled=true;
          setDisplay(!display);
          }
        }
        // className="mt-4 rounded-md bg-slate-900 px-4 py-2 font-medium">
        className="aboutbutton">
        Trigger animation
      </button>
      { display && <AboutRouletteCanvas /> }
    </>
  )
}
export default AboutScroll4