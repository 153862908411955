import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

const SampleHome = () => {
  return (
    <motion.div className='home container'
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ delay: 0.2, duration: 1.5 }}
    >
      <h2>サンプルホーム</h2>
      <Link to="/">
        <motion.button
          whileHover={{
            scale: 1.1,
            textShadow: "0px 0px 8px rgb(255, 255, 255)",
            boxShadow: "0px 0px 8px rgb(255, 255, 255)",
          }}
          >
          戻る
        </motion.button>
      </Link>
    </motion.div>
  )
}

export default SampleHome
