import SampleanimeNavbar from "./Sampleanime/SampleanimeNavbar";
import SampleanimeMain from "./Sampleanime/SampleanimeMain";

const Sampleanime = () => {
  return (
    <>
      <div className="SampleanimeNavbar">
        <SampleanimeNavbar />
      </div>
      <div className="SampleanimeMain">
        <SampleanimeMain />
      </div>
    </>
  )
}
export default Sampleanime
