import { useAnimate, } from "framer-motion";
import { useState, useEffect } from "react";

const SampleContact = () => {

  const [ scope, animate ] = useAnimate()
  const [ selectedNumber, setSelectedNumber ] = useState(0);

  const handleRouletteStart = async() => {
    // ボタンを押された感じ
    animate([
      ["button", {scale: 0.8}, {duration: 0.1, at: "<"}],
      ["button", {scale: 1}, {duration: 0.1}],
    ])

    setSelectedNumber(randomNumberInRange(1, 100));
  }

  const randomNumberInRange = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  useEffect(() => {
    console.log('reload selectedNumber:', selectedNumber);
  }, [selectedNumber]);

  return (
    <>
      <div ref={scope}>
        <div id='target' />
          <button className="mt-4 rounded-md rouletteStartButton px-4 py-2" onClick={() => handleRouletteStart()}>
            ルーレットスタート
          </button>
        </div>
      <div className="square">
          { selectedNumber }
      </div>
    </>
  )
}

export default SampleContact
