// import "../styles.css";
import { motion, animate, useMotionValue, useTransform } from "framer-motion";
import { useEffect } from "react";
// import { AnimatePresence } from "framer-motion";
// import { useState } from "react";

import About from "./About";

const variants = {
  open: { opacity: 1, x: 0 },
  closed: { opacity: 0, x: "-100%" },
}

const Slot = () => {
  const count = useMotionValue(0);
  const rounded = useTransform(count, Math.round);

  useEffect(() => {
    const animation = animate(count, 100, { duration: 10 });

    return animation.stop;
  }, []);
  
      return (
    <>
        <motion.h1>
            {rounded}
        </motion.h1>
    </>
  )
}
export default Slot