import { motion, useAnimationControls, animate, useMotionValue, useTransform, color, } from "framer-motion";
import { useEffect, useState, useContext } from "react";
import { Box, Slider, Typography, Switch, Input, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, TextField, InputAdornment } from "@mui/material";
import { AppContext } from "../../App"

import styled from "@emotion/styled";

const StyledButton = styled.button`
  color: ${({ active }) => active ? '#ff99ff' : 'lightgray'};
  background: ${({ active }) => active ? 'linear-gradient(180deg, #660033, #9900cc)' : 'linear-gradient(180deg, #100c08, #660033)'};
  ${({ active }) => active && `text-shadow: lightgray 5px 5px 10px; box-shadow: #ff99ff 5px 5px 10px;`}
`;

function valueLabelFormat(value) {
  return value;
}

function calculateValue(value) {
  return value;
}

const marks = [
  { value: 2, label: '2', },
  { value: 3, label: '3', },
  { value: 4, label: '4', },
  { value: 5, label: '5', },
  { value: 6, label: '6', },
  { value: 7, label: '7', },
  { value: 8, label: '8', },
  { value: 9, label: '9', },
  { value: 10, label: '10', },
];

const SettingItems = () => {

  const [ extendCookies, setExtendCookies ] = useState(false);

  const { cookies, setCookie, removeCookie } = useContext(AppContext);
  // cookieの有効期限を365日に設定
  const expires = new Date(Date.now() + 365*24*60*60*1000 );

  const handleCookieSave = async() => {
    // cookie保存ボタン押下時＋365日をexpiredに設定
    setExtendCookies(true);
    const extendedExpires = new Date(Date.now() + 365*24*60*60*1000 );
    setCookie('title', cookies.title ? cookies.title : '', { expires: extendedExpires });
    setCookie('maxNumber', cookies.maxNumber ? cookies.maxNumber : 10, { expires: extendedExpires });
    setCookie('item01', cookies.item01 ? cookies.item01 : '', { expires: extendedExpires });
    setCookie('item02', cookies.item02 ? cookies.item02 : '', { expires: extendedExpires });
    setCookie('item03', cookies.item03 ? cookies.item03 : '', { expires: extendedExpires });
    setCookie('item04', cookies.item04 ? cookies.item04 : '', { expires: extendedExpires });
    setCookie('item05', cookies.item05 ? cookies.item05 : '', { expires: extendedExpires });
    setCookie('item06', cookies.item06 ? cookies.item06 : '', { expires: extendedExpires });
    setCookie('item07', cookies.item07 ? cookies.item07 : '', { expires: extendedExpires });
    setCookie('item08', cookies.item08 ? cookies.item08 : '', { expires: extendedExpires });
    setCookie('item09', cookies.item09 ? cookies.item09 : '', { expires: extendedExpires });
    setCookie('item10', cookies.item10 ? cookies.item10 : '', { expires: extendedExpires });
    setCookie('rouletteSound', cookies.rouletteSound ? cookies.rouletteSound : false, { expires: extendedExpires });
  }

  const handleCookieClear = async() => {
    // cookies.title && removeCookie('title');
    // cookies.maxNumber && removeCookie('maxNumber');
    // cookies.item01 && removeCookie('item01');
    // cookies.item02 && removeCookie('item02');
    // cookies.item03 && removeCookie('item03');
    // cookies.item04 && removeCookie('item04');
    // cookies.item05 && removeCookie('item05');
    // cookies.item06 && removeCookie('item06');
    // cookies.item07 && removeCookie('item07');
    // cookies.item08 && removeCookie('item08');
    // cookies.item09 && removeCookie('item09');
    // cookies.item10 && removeCookie('item10');
    // cookies.rouletteSound && removeCookie('rouletteSound');
    // cookie保存ボタン押下時＋365日をexpiredに設定
    // setExtendCookies(true);
    const extendedExpires = new Date(Date.now() + 365*24*60*60*1000 );
    setCookie('title', 'モスハンバーガーメニュー', { expires: extendedExpires });
    setCookie('item01', 'モスバーガー', { expires: extendedExpires });
    setCookie('item02', 'モスチーズバーガー', { expires: extendedExpires });
    setCookie('item03', 'グリーンバーガー＜テリヤキ＞', { expires: extendedExpires });
    setCookie('item04', 'テリヤキチキンバーガー', { expires: extendedExpires });
    setCookie('item05', 'テリヤキバーガー', { expires: extendedExpires });
    setCookie('item06', 'モス野菜バーガー', { expires: extendedExpires });
    setCookie('item07', 'フィッシュバーガー', { expires: extendedExpires });
    setCookie('item08', 'ロースカツバーガー', { expires: extendedExpires });
    setCookie('item09', '海老カツバーガー', { expires: extendedExpires });
    setCookie('item10', 'チキンバーガー', { expires: extendedExpires });
    setCookie('rouletteSound', false, { expires: extendedExpires });
  }

  // const [rouletteSoundSwitchChecked, setRouletteSoundSwitchChecked] = useState(cookies.rouletteSound);

  // const handleChangeRouletteSoundSwitch = (event) => {
  //   setRouletteSoundSwitchChecked(event.target.checked);
  // };
  
  // 1からmaxNumberまでの数字を抽選
  const [ maxNumber, setMaxNumber ] = useState(cookies.maxNumber ? cookies.maxNumber : 10);

  const handleChangeMaxNumberSlider = async(event, newMaxNumber) => {
    console.log('event(MaxNumberSlider):', event);
    setMaxNumber(newMaxNumber);
    setCookie('maxNumber', newMaxNumber, { expires: expires });
  };

  const itemMaxLength = 15;

  return (
    <>
      <div className="controlPanel">
        <div className="controlPanelHeader">設定内容はこのブラウザのCookieに保存します</div>
        <Box className="itemBox">
        <span className="controlPanelTitle">Cookie保存</span>
        <StyledButton onClick={() => handleCookieSave()} style={{width: '200px', height: '70px'}}>
        有効期間を延長<br />（{ new Date(Date.now() + 365*24*60*60*1000 ).toLocaleDateString() }）
        </StyledButton>
        {/* Sat, 17 May 2024 12:40:28 GMT 形式：.toUTCString() */}
        {/* 2024/1/1 20:08:05 形式：.toLocaleString() */}
        <span style={{marginLeft: '20px'}}>{ extendCookies && '延長完了（' + new Date(Date.now() + 365*24*60*60*1000 ).toLocaleDateString() + 'まで）' }</span>
        <StyledButton onClick={() => handleCookieClear()} style={{marginLeft: '20px',width: '150px', height: '40px'}}>
          項目リセット<br />
        </StyledButton>
        </Box>

      <Box className="itemBox">
        <span className="controlPanelTitle">タイトル</span>
        <TextField
          className="itemText"
          id="title"
          label={"タイトル（" + "残り" + (itemMaxLength - (cookies.title ? cookies.title.length :0)).toString() + "文字）"}
          sx={{display: "flex", m: 2, maxWidth: 300}}
          autoComplete="off"
          value={cookies.title}
          onChange={(e) => setCookie('title', e.target.value, { expires: expires })}
          inputProps={{
            maxLength: itemMaxLength,
          }}
        />
      </Box>
      <Box className='maxNumberSliderBox'>
        <span className="controlPanelTitle">ルーレット<br />最大値設定</span>
        <Slider className="maxNumberSlider"
          marks={marks}
          value={maxNumber}
          min={2}
          step={1}
          max={10}
          scale={calculateValue}
          getAriaValueText={valueLabelFormat}
          valueLabelFormat={valueLabelFormat}
          track={false}
          onChangeCommitted={handleChangeMaxNumberSlider}
          valueLabelDisplay="on"
        />
      </Box>
        <Box className="itemBox">
          <span className="controlPanelTitle">項目設定</span>
          <TextField
            className="itemText"
            id="item01"
            label={"項目①（" + "残り" + (itemMaxLength - (cookies.item01 ? cookies.item01.length :0)).toString() + "文字）"}
            sx={{display: "flex", m: 2, maxWidth: 300}}
            autoComplete="off"
            // variant="standard"
            value={cookies.item01}
            onChange={(e) => {setCookie('item01', e.target.value, { expires: expires })}}
            // helperText={"残り" + (itemMaxLength - (cookiesItems.item02 ? cookiesItems.item02.length :0)).toString() + "文字"}
            inputProps={{
              maxLength: itemMaxLength,
            }}
          />
          <TextField
            className="itemText"
            id="item02"
            label={"項目②（" + "残り" + (itemMaxLength - (cookies.item02 ? cookies.item02.length :0)).toString() + "文字）"}
            sx={{display: "flex", m: 2, maxWidth: 300}}
            autoComplete="off"
            value={cookies.item02}
            onChange={(e) => setCookie('item02', e.target.value, { expires: expires })}
            inputProps={{
              maxLength: itemMaxLength,
            }}
          />
          <TextField
            className="itemText"
            id="item03"
            label={"項目③（" + "残り" + (itemMaxLength - (cookies.item03 ? cookies.item03.length :0)).toString() + "文字）"}
            sx={{display: "flex", m: 2, maxWidth: 300}}
            autoComplete="off"
            value={cookies.item03}
            onChange={(e) => setCookie('item03', e.target.value, { expires: expires })}
            inputProps={{
              maxLength: itemMaxLength,
            }}
          />
          <TextField
            className="itemText"
            id="item04"
            label={"項目④（" + "残り" + (itemMaxLength - (cookies.item04 ? cookies.item04.length :0)).toString() + "文字）"}
            sx={{display: "flex", m: 2, maxWidth: 300}}
            autoComplete="off"
            value={cookies.item04}
            onChange={(e) => setCookie('item04', e.target.value, { expires: expires })}
            inputProps={{
              maxLength: itemMaxLength,
            }}
          />
          <TextField
            className="itemText"
            id="item05"
            label={"項目⑤（" + "残り" + (itemMaxLength - (cookies.item05 ? cookies.item05.length :0)).toString() + "文字）"}
            sx={{display: "flex", m: 2, maxWidth: 300}}
            autoComplete="off"
            value={cookies.item05}
            onChange={(e) => setCookie('item05', e.target.value, { expires: expires })}
            inputProps={{
              maxLength: itemMaxLength,
            }}
          />
          <TextField
            className="itemText"
            id="item06"
            label={"項目⑥（" + "残り" + (itemMaxLength - (cookies.item06 ? cookies.item06.length :0)).toString() + "文字）"}
            sx={{display: "flex", m: 2, maxWidth: 300}}
            autoComplete="off"
            value={cookies.item06}
            onChange={(e) => setCookie('item06', e.target.value, { expires: expires })}
            inputProps={{
              maxLength: itemMaxLength,
            }}
          />
          <TextField
            className="itemText"
            id="item07"
            label={"項目⑦（" + "残り" + (itemMaxLength - (cookies.item07 ? cookies.item07.length :0)).toString() + "文字）"}
            sx={{display: "flex", m: 2, maxWidth: 300}}
            autoComplete="off"
            value={cookies.item07}
            onChange={(e) => setCookie('item07', e.target.value, { expires: expires })}
            inputProps={{
              maxLength: itemMaxLength,
            }}
          />
          <TextField
            className="itemText"
            id="item08"
            label={"項目⑧（" + "残り" + (itemMaxLength - (cookies.item08 ? cookies.item08.length :0)).toString() + "文字）"}
            sx={{display: "flex", m: 2, maxWidth: 300}}
            autoComplete="off"
            value={cookies.item08}
            onChange={(e) => setCookie('item08', e.target.value, { expires: expires })}
            inputProps={{
              maxLength: itemMaxLength,
            }}
          />
          <TextField
            className="itemText"
            id="item09"
            label={"項目⑨（" + "残り" + (itemMaxLength - (cookies.item09 ? cookies.item09.length :0)).toString() + "文字）"}
            sx={{display: "flex", m: 2, maxWidth: 300}}
            autoComplete="off"
            value={cookies.item09}
            onChange={(e) => setCookie('item09', e.target.value, { expires: expires })}
            inputProps={{
              maxLength: itemMaxLength,
            }}
          />
          <TextField
            className="itemText"
            id="item10"
            label={"項目⑩（" + "残り" + (itemMaxLength - (cookies.item10 ? cookies.item10.length :0)).toString() + "文字）"}
            sx={{display: "flex", m: 2, maxWidth: 300}}
            autoComplete="off"
            value={cookies.item10}
            onChange={(e) => setCookie('item10', e.target.value, { expires: expires })}
            inputProps={{
              maxLength: itemMaxLength,
            }}
          />
        </Box>
        {/* <span className="controlPanelTitle">サウンド</span>
        <Switch className="rouletteSoundSwitch"
        rouletteSoundSwitchChecked={rouletteSoundSwitchChecked}
        onChange={handleChangeRouletteSoundSwitch}
        // inputProps={{ 'aria-label': 'controlled' }}
        /> */}
      </div>
      </>
  )
}
export default SettingItems
