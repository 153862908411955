import { motion, useTransform, useScroll } from "framer-motion";
import { useRef } from "react";
// import "../styles.css";

const data = [
  {
    id: 1,
    text: "Canyon",
    url: "https://images.pexels.com/photos/19561297/pexels-photo-19561297.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load",
  },
  {
    id: 2,
    text: "Kyoto",
    url: "https://images.pexels.com/photos/19488566/pexels-photo-19488566/free-photo-of-kyoto.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load",
  },
  {
    id: 3,
    text: "Forest",
    url: "https://images.pexels.com/photos/19237996/pexels-photo-19237996/free-photo-of-empty-road-in-forest.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load",
  },
  {
    id: 4,
    text: "Vietnam",
    url: "https://images.pexels.com/photos/18707547/pexels-photo-18707547/free-photo-of-a-curved-road-in-the-mountains-with-a-motorcycle.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load",
  },
];

function Images({ text, url }) {
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({ target: ref });
  const y = useTransform(scrollYProgress, [0, 1], [-100, 50]);
  return (
    <section>
      <div ref={ref}>
        <img src={url} alt={text} />
      </div>
      <motion.h2 style={{ y }}>{text}</motion.h2>
    </section>
  );
}

const Cat1ScrollY = () => {
  return (
    <>
      <span>
        index.cssのbodyのoverflow: hidden;をコメントアウトしないと動きません
      </span>
      {data.map((img) => (
        <Images key={img.id} text={img.text} url={img.url} />
      ))}
    </>
  );
}
export default Cat1ScrollY
