import { NavLink } from 'react-router-dom'

import { useState } from "react";
import styled from "@emotion/styled";

const StyledButton = styled.button`
  color: ${({ active }) => active ? '#66ff33' : 'lightgray'};
  background: ${({ active }) => active ? 'linear-gradient(180deg, #003300, #006633)' : 'linear-gradient(180deg, #100c08, #003300)'};
  ${({ active }) => active && `text-shadow: lightgray 5px 5px 10px; box-shadow: #66ff33 5px 5px 10px;`}
`;

// navsの最初の''はホームを表す
const navs = ['', 'aboutscroll1', 'aboutscroll2', 'aboutscroll3', 'aboutscroll4', 'aboutcontext', 'aboutreducer', 'aboutvalue', ];
const navsJ = ['aboutホーム', 'aboutスク1', 'aboutスク2', 'aboutスク3', 'aboutスク4', 'aboutコンテ', 'aboutReducer', 'aboutValue', ];

const AboutNavbar = () => {

  const [active, setActive] = useState(navs[0]);

  return (
    <>
      {navs.map((nav, index) => {
        return (
          <span key={index}>
            <NavLink to={nav}>
              <StyledButton key={nav}
                active={active === nav ? +true : +false }
                onClick={() => setActive(nav)}
                >{navsJ[index]}
              </StyledButton>
            </NavLink>
          </span>
        )}
      )}
    </>
  )
}

export default AboutNavbar
