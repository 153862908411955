import { Routes, Route, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

import BoxHome from "./BoxHome";
import BoxAnimate from "./BoxAnimate";
import BoxCountdown from "./BoxCountdown";
import BoxAbout from "./BoxAbout";
import BoxContact from "./BoxContact";
import BoxSlot from "./BoxSlot";
import BoxModal from "./BoxModal";

const BoxMain = () => {
  const location = useLocation();
  return (
    <>
      <AnimatePresence>
        <Routes location={location} key={Location.pathname}>
          <Route element={<BoxHome />} exact path="/" />
          <Route element={<BoxHome />} path="/boxhome" />
          <Route element={<BoxAnimate />} path="/boxanimate" />
          <Route element={<BoxCountdown />} path="/boxcountdown" />
          <Route element={<BoxAbout />} path="/boxabout" />
          <Route element={<BoxContact />} path="/boxcontact" />
          <Route element={<BoxSlot />} path="/boxslot" />
          <Route element={<BoxModal />} path="/boxmodal" />
        </Routes>
      </AnimatePresence>
    </>
  )
}
export default BoxMain
