import { useContext } from "react";
import { AppContext } from "../../App"

const AboutValue = () => {
  const { gValue, gDispatch } = useContext(AppContext);

  return (
    <>
      <div>AboutValue</div>
      <div>AboutContextこっちはApp.jsのレベルで変数を持っているから、どの画面に行っても初期化されない</div>
      <button onClick={() => gDispatch('add_1')}>Add + 1</button>
      <button onClick={() => gDispatch('add_1000')}>Add + 1000</button>
      <button onClick={() => gDispatch('multiple_3')}>Multiple * 3</button>
      <button onClick={() => gDispatch('reset')}>Reset</button>

      gValue:{ gValue }
      <div className="relative mx-auto grid h-32 w-96 place-content-center">
        <h1 className="relative z-0 text-3xl font-black uppercase">
          Show me on scroll
        </h1>
        <div className="absolute bottom-0 left-0 right-0 top-0 z-10 bg-indigo-500" />
      </div>
    </>
  )
}
export default AboutValue