import { NavLink } from 'react-router-dom'
import { useState } from "react";
import styled from "@emotion/styled";

const StyledButton = styled.button`
  width: 100px;
  color: ${({ active }) => active ? '#66ffff' : 'lightgray'};
  background: ${({ active }) => active ? 'linear-gradient(180deg, #000099, #0033ff)' : 'linear-gradient(180deg, #100c08, #000099)'};
  ${({ active }) => active && `text-shadow: lightgray 5px 5px 10px; box-shadow: #66ffff 5px 5px 10px;`}
`;

// const navs = ['Home', 'SampleAnime', 'Cat1', 'About', 'Contact', 'Roulette', 'Slot', 'Box', ];
// const navsJ = ['ホーム', 'サンプルアニメ', 'カテゴリ１', 'about', '連絡', 'ルーレット', 'スロット', 'ボックス', ];

// const navs = ['Personal', 'Settings', 'HowToUse', ];
// const navsJ = ['個人用', '設定', '使い方', ];

// const navs = ['Spin', 'Spin10', 'Spin100', ];
// const navsJ = ['Spin', 'Spin[1-10]', 'Spin[10-100]', ];

const navs = ['Spin', 'Settings', 'HowToUse' ];
const navsJ = ['スピン', '設定', '使い方' ];

const Navbar = () => {

  const [active, setActive] = useState(navs[0]);

  return (
    <>
      {navs.map((nav, index) => {
        return (
          <span key={index}>
            <NavLink to={nav}>
              <StyledButton key={nav}
                active={active === nav ? +true : +false }
                onClick={() => setActive(nav)}
              >
                {navsJ[index]}
              </StyledButton>
            </NavLink>
          </span>
        )}
      )}
    </>
  )
}

export default Navbar
