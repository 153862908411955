import "./Type.css";
import { motion, useAnimationControls, animate, useMotionValue, useTransform, } from "framer-motion";
import { useEffect, useState, useContext } from "react";
import useSound from "use-sound";
import { AppContext } from "../../App"

import inRaffleMp3 from '../../assets/sounds/inRaffle.mp3';
import stopMp3 from '../../assets/sounds/stop.mp3';

const Normal = () => {
  const { gHistoriesArrayState, setGHistoriesArrayState } = useContext(AppContext);

  const [playInRaffleSound] = useSound(inRaffleMp3);
  const [playStopSound] = useSound(stopMp3);

  const divAnimationControls = useAnimationControls();
  // 1からmaxNumberまでの数字を抽選
  let maxNumber = 10;
  // ルーレット回転数
  let rouletteRoundCounter = 2;
  // 止まるまでの時間
  let stopTimer = 2;

  // selectedNumberState：回転数を含めた抽選番号
  // 例えば1～100（maxNumber）の抽選で、ルーレット回転数が2の場合、201～300の整数値が抽選される
  const [ selectedNumberState, setSelectedNumberState ] = useState(0);

  // displayNumberState：画面表示用の回転数を含まない抽選番号
  // 値はanimate内で導出、更新される
  const [ displayNumberState, setDisplayNumberState ] = useState(0);

  // nowInRaffleState：抽選中の場合、trueにしてボタンを無効に設定
  const [ nowInRaffleState, setNowInRaffleState ] = useState(false);

  // nowInRaffleMotionValue：animateの中で使用し、小数を含めてselectedNumberStateの数字へ徐々に近づく値を設定
  const nowInRaffleMotionValue = useMotionValue(0);

  // intNowInRaffleTransform：animateの中で使用し、nowInRaffleMotionValueの変化がある度に表示する値に更新
  const intNowInRaffleTransform = useTransform(nowInRaffleMotionValue, Math.round);

  // randomNumberInRange(min, max)の引数で指定された整数の最小値、最大値に含まれる整数をランダムで選定
  const randomNumberInRange = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  const handleRouletteStart = async() => {

    // 抽選中のため、ボタンを無効にする
    setNowInRaffleState(true);

    // 0からスタート
    nowInRaffleMotionValue.set(0);
    await setSelectedNumberState(0);

    // 1～Xの数字をランダムでget
    // 「maxNumber * rouletteRoundCounter + 1」は数字がrouletteRoundCounter周後に止まる
    // setSelectedNumberState(randomNumberInRange(201, 300));
    setSelectedNumberState(randomNumberInRange(maxNumber * rouletteRoundCounter + 1, maxNumber * (rouletteRoundCounter + 1)));
  }

  const addGHistoriesArrayState = (displayNumberState) => {
    setGHistoriesArrayState([...gHistoriesArrayState, displayNumberState]);
  }

  animate(nowInRaffleMotionValue, selectedNumberState, {
    duration: stopTimer,
    onUpdate: latest => {
      // console.log(
      //   ' selectedNumberState:', selectedNumberState, ' intNowInRaffleTransform:', intNowInRaffleTransform.get(), ' nowInRaffleMotionValue:', nowInRaffleMotionValue.get(),
      //   ' nowInRaffleState:', nowInRaffleState,
      //   ' latest:', latest );

      // 例えば1～100（maxNumber）の抽選中で、intNowInRaffleTransform.get()が155の場合、
      // 155を100（maxNumber）で割った剰余55（0～99）に1を加えて56として表示（1～100）
      setDisplayNumberState(intNowInRaffleTransform.get() % maxNumber + 1);
    }
  });

  useEffect(() => {
    divAnimationControls.start({
      background: ['linear-gradient(180deg, #1d1901, #000000)', 'linear-gradient(180deg, #09078b, #3118b9)'],
      border: ['5px solid #0d045e', '5px solid #033033'],
      color: ['#5c5606', '#ebe728'],
      width: 150,
      transition: {
        duration: 0.2,
      }
    });

    // 数字が変わるタイミングで音出力
    playInRaffleSound();

    // 数字が変わるタイミングでログ出力
    console.log(
      ' selectedNumber:', selectedNumberState,
      ' intNowInRaffle:', intNowInRaffleTransform.get(),
      ' displayNumber:', displayNumberState,
    );

    // 抽選数字になれば、決定音を鳴らして背景色を変更する
    if(selectedNumberState === intNowInRaffleTransform.get()) {
      playStopSound();
      divAnimationControls.start({
        background: 'linear-gradient(180deg, #161d81, #391f97)',
        border: '5px solid #d2e716',
        borderStyle: 'inset',
        color: ['#62e616', '#eef125'],
        scale: [1.0, 1.0],
        transition: {
          duration: 0.1,
        },
      });
      // 抽選が終ったため、ボタンを有効にする
      setNowInRaffleState(false);
      // 履歴表示
      addGHistoriesArrayState(displayNumberState);
    }
  }, [displayNumberState]);
  // }, [intNowInRaffleTransform.get()]);

  return (
    <>
      <div className="Canvas">
        <motion.div className="RouletteCanvas"
          animate={divAnimationControls}
          // returnの処理内に関数を埋め込んでuseAnimationControls HooksでanimationCompleteで処理をする場合
          // onAnimationComplete={
          //   async definition => {
          //     console.log('Completed animating', definition);
          //   }
          // }
        >
          { displayNumberState }
        </motion.div>
        <button disabled={nowInRaffleState} onClick={() => handleRouletteStart()}>
          抽選
        </button>
      </div>
    </>
  )
}
export default Normal