import AboutNavbar from "./About/AboutNavbar";
import AboutMain from "./About/AboutMain";
import SlideInTransition from './SlideInTransition'

const About = () => {
  return (
    <>
      <div>
        <AboutNavbar />
      </div>
      <div>
        {/* <SlideInTransition> */}
        <AboutMain />
        {/* </SlideInTransition> */}
      </div>
    </>
  );
};

// export default transition(About);
export default About;
