import SettingsNavbar from "./Settings/SettingsNavbar";
import SettingsMain from "./Settings/SettingsMain";

const Settings = () => {
  return (
    <>
      <div className="SettingsNavbar">
        <SettingsNavbar />
      </div>
      <div className="SettingsMain">
        <SettingsMain />
      </div>
    </>
  )
}
export default Settings
