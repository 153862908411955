import { motion, useAnimationControls, animate, useMotionValue, useTransform, color, } from "framer-motion";
import { useEffect, useState, useContext } from "react";
import { Box, Checkbox, Slider, Typography, Switch, Input, FormGroup, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, TextField, InputAdornment } from "@mui/material";
import { AppContext } from "../../App"

import styled from "@emotion/styled";
import { scryRenderedComponentsWithType } from "react-dom/test-utils";

const StyledButton = styled.button`
  color: ${({ active }) => active ? '#ff99ff' : 'lightgray'};
  background: ${({ active }) => active ? 'linear-gradient(180deg, #660033, #9900cc)' : 'linear-gradient(180deg, #100c08, #660033)'};
  ${({ active }) => active && `text-shadow: lightgray 5px 5px 10px; box-shadow: #ff99ff 5px 5px 10px;`}
`;

function valueLabelFormat(value) {
  return value;
}

function calculateValue(value) {
  return value;
}

const SettingDirection = () => {

  const { cookies, setCookie, removeCookie } = useContext(AppContext);

  // cookieの有効期限を365日に設定
  // const expires = new Date(Date.now() + 365 * 24 * 60 * 60 * 1000);

  // デフォルト設定
  if(!cookies.directionItIsTurning){
    setCookie('directionItIsTurning', 'clockwise', { expires: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000) });
  }
  if(!cookies.startPosition){
    setCookie('startPosition', 'right', { expires: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000) });
  }

  if(!cookies.displayList){
    setCookie('displayList', 'allList', { expires: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000) });
  }

  const [directionItIsTurning, setDirectionItIsTurning] = useState(cookies.directionItIsTurning ? cookies.directionItIsTurning : 'clockwise');
  const [startPosition, setStartPosition] = useState(cookies.startPosition ? cookies.startPosition : 'right');
  const [displayList, setDisplayList] = useState(cookies.displayList ? cookies.displayList : 'allList');

  // const [displayAllList, setDisplayAllList] = useState(cookies.displayAllList);
  // const [displaySelectedList, setDisplaySelectedList] = useState(cookies.displaySelectedList);


  // const [displayLists, setDisplayLists] = useState({
  //   displayAllList: cookies.displayAllList ? cookies.displayAllList : true,
  //   displaySelectedList: cookies.displaySelectedList ? cookies.displaySelectedList : true,
  // });

  // const handleDisplayListsChange = (event) => {
  //   setCookie([event.target.name], event.target.checked, { expires: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000) })
  // }

  // const handleSetDirectionItIsTurning = async (event, value) => {
  //   alert(event.target.value)
  //   console.log('event:', event);
  //   console.log('value:', value);
  // };


  // useEffect(() => {
  //   setDisplayAllList(cookies.displayAllList);
  //   setDisplaySelectedList(cookies.dispalySelectedList);
  // }, [cookies]);

  return (
    <>
      <div className="controlPanel">

        <Box className="FormBox">
          <FormControl>
            <FormLabel>回転方向</FormLabel>
            <RadioGroup
              row
              aria-labelledby="directionItIsTurning-group-label"
              name="directionItIsTurning-group"
            >
              <FormControlLabel value="clockwise" control={<Radio />} checked={cookies.directionItIsTurning === 'clockwise'} label="右回り" onClick={(e) => { setCookie('directionItIsTurning', e.target.value, { expires: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000) }) }} />
              <FormControlLabel value="counter-clockwise" control={<Radio />} checked={cookies.directionItIsTurning === 'counter-clockwise'} label="左回り" onClick={(e) => { setCookie('directionItIsTurning', e.target.value, { expires: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000) }) }} />
            </RadioGroup>
          </FormControl>
        </Box>

        {/* <Box className="FormBox">
          <FormControl>
            <FormLabel>スタート位置</FormLabel>
            <RadioGroup
              row
              aria-labelledby="startPosition-group-label"
              name="startPosition-group"
            >
              <FormControlLabel value='up' control={<Radio />} checked={cookies.startPosition === 'up'} label="上" onClick={(e) => { setCookie('startPosition', e.target.value, { expires: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000) }) }} />
              <FormControlLabel value='right' control={<Radio />} checked={cookies.startPosition === 'right'} label="右" onClick={(e) => { setCookie('startPosition', e.target.value, { expires: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000) }) }} />
            </RadioGroup>
          </FormControl>
        </Box> */}

        {/* <Box className="FormBox">
          <FormControl>
            <FormLabel>全リスト表示</FormLabel>
            <RadioGroup
              row
              aria-labelledby="displayList-group-label"
              name="displayList-group"
            >
              <FormControlLabel value='allList' control={<Radio />} checked={cookies.displayList === 'allList'} label="する" onClick={(e) => { setCookie('displayList', e.target.value, { expires: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000) }) }} />
              <FormControlLabel value='selectedList' control={<Radio />} checked={cookies.displayList === 'selectedList'} label="しない" onClick={(e) => { setCookie('displayList', e.target.value, { expires: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000) }) }} />
            </RadioGroup>
          </FormControl>
        </Box> */}
      </div>
    </>
  )
}
export default SettingDirection
