import { motion, useAnimationControls, animate, useMotionValue, useTransform, useScroll } from "framer-motion";
import { useEffect, useState, useRef } from "react";

const Cat1Start = () => {

  const divControls = useAnimationControls();
  const [ selectedNumber, setSelectedNumber ] = useState(0);
  const [ nowInRaffle, setNowInRaffle ] = useState(false);

  const count = useMotionValue(0);
  const rounded = useTransform(count, Math.round);

  const randomNumberInRange = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  const handleRouletteStart = async() => {
    
    // setNowInRaffle(true);

    // 0からスタート
    count.set(0);
    await setSelectedNumber(0);

    // 1～Xの数字をランダムでget
    setSelectedNumber(randomNumberInRange(301, 400));
  }

  const modSelectedNumber = selectedNumber % 100;

  // const animation = animate(count, selectedNumber, {
  //   duration: 1,
  //   onUpdate: latest => console.log(' selectedNumber:', selectedNumber, ' rounded:', rounded.get(), ' count:', count.get(), ' latest:', latest ),
  // });
  animate(count, selectedNumber, {
    duration: 1,
    onUpdate: latest => console.log(' selectedNumber:', selectedNumber, ' rounded:', rounded.get(), ' count:', count.get(), ' latest:', latest ),
  });

  useEffect(() => {
    divControls.start({
      x: ['0px', '1000px', '1000px', '-1000px', '-1000px', '0px'],
      y: ['0px', '0px', '-1200px', '-1200px', '0px', '0px'],
      backgroundColor: ['#00f', '#fff', '#0f0', ],
      transition: {
        duration: 2,
      },
    });
    // animate(count, selectedNumber, {
    //   duration: 3,
    //   onUpdate: latest => console.log(' selectedNumber:', selectedNumber, ' rounded:', rounded.get(), ' count:', count.get(), ' latest:', latest ),
    // });
  }, [selectedNumber]);


  const variants = {
    active: {
      backgroundColor: "#f00"
    },
    inactive: {
      backgroundColor: "#fff",
      transition: { duration: 2 }
    },
    divControls: {
      backgroundColor: "#ff0",
      transition: { duration: 2 }
    },
  }
  return (
    <>
      <div>
        <div id='target' />
          <button disabled={nowInRaffle} onClick={() => handleRouletteStart()}>
            ルーレットスタート
          </button>
        </div>
      {/* <motion.h1 className='home container' animate={divControls}> */}
      {/* <motion.h1 variants={variants} className='container' animate='active'> */}
      {/* <motion.h1 variants={variants} className='container' animate='divControls'> */}
      <motion.h1 initial={{ opacity: 1}} variants={variants} className='container' animate={divControls}>
          { rounded }
      </motion.h1>
      <motion.h1 className='home container'>
          { count }
      </motion.h1>
      { modSelectedNumber }
    </>
  )
}

export default Cat1Start

