import { useState } from "react";
import { useAnimate } from "framer-motion"

const SampleAbout = ({children}) => {
const [modalOpen, setModalOpen] = useState(false);
const handleButtonClick = () => {
  setModalOpen(false);
}
  
  const [ scope, animate ] = useAnimate()
  const handleAnimate = async() => {
    // alert('hello world')
    // animate('#target', { opacity: 0 })
    await animate('#target', { x: 150 })
    await animate('#target', { y: 150, rotate: '360deg', }, { duration: 0.5 })
    await animate('#target', { borderRadius: '100%', }, { duration: 0.3 })
    await animate('#target', { x: 0, borderRadius: '25px', rotate: '180deg', background: '#f59e0b', })

    await animate('button', { rotate: '360deg' }, { duration: 1.0 })
    await animate('button', { rotate: '0deg' }, { duration: 0 })

    await animate('#target', { y: 0, borderRadius: '0px', rotate: '0deg', }, { duration: 0.5 })
    await animate('#target', { x: 0, background: '#199681', })
  }

  return (
    <>
      <div className='modal-header'>
        <p className='close'>&times;</p>
      </div>
      <div className='modal-content'>
        {children}
        This is the modal title
      </div>
      <div className='modal-footer'>
        <button className='btn btn-submit'>Submit</button>
        <button className='btn btn-cancel'>Cancel</button>
      </div>
      <div ref={scope}>
        <div id='target' className="h-24 w-24 rbox container" />
        <button className="mt-4 rounded-md boxanimatebutton px-4 py-2" onClick={() => handleAnimate()}>
          Trigger animation
        </button>
      </div>
    </>
  )
}
  
export default SampleAbout
