import { useReducer, createContext } from "react";
import AboutReducerCanvas from "./AboutReducerCanvas";

export const AboutContext = createContext();

const InitialState = 0;
const reducer = (currentState, action) => {
  switch (action) {
    case "add_1":
      return currentState + 1;
    case "multiple_3":
      return currentState * 3;
    case "reset":
      return InitialState;
    default:
      return currentState;
  }
};

const AboutReducer = () => {
  const [lValue, lDispatch] = useReducer(reducer, InitialState);
 
  return (
    <>
      <AboutContext.Provider
        value={{
          lValue,
          lDispatch,
        }}
      >
        <AboutReducerCanvas />
        <div>AboutReducerボタンで初期化しているから、画面遷移毎にリセットされる</div>
      </AboutContext.Provider>
    </>
  )
}

export default AboutReducer
