import { Routes, Route, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

import HowToUseDistribution from "./HowToUseDistribution";
import HowToUseAttension from "./HowToUseAttension";

const HomeMain = () => {
  const location = useLocation();
  return (
    <>
      <AnimatePresence>
        <Routes location={location} key={Location.pathname}>
          <Route element={<HowToUseAttension />} exact path="/" />
          <Route element={<HowToUseAttension />} path="/howtouseattension" />
          <Route element={<HowToUseDistribution />} path="/howtousedistribution" />
        </Routes>
      </AnimatePresence>
    </>
  )
}
export default HomeMain