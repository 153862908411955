import { NavLink } from 'react-router-dom'
import { useState } from "react";
import styled from "@emotion/styled";

const StyledButton = styled.button`
  color: ${({ active }) => active ? '#cccccc' : 'lightgray'};
  background: ${({ active }) => active ? 'linear-gradient(180deg, #666666, #999999)' : 'linear-gradient(180deg, #100c08, #666666)'};
  ${({ active }) => active && `text-shadow: lightgray 5px 5px 10px; box-shadow: #cccccc 5px 5px 10px;`}
`;

const navs = ['Cat1Home', 'Cat1Countdown', 'Cat1Start', 'Cat1Start2', 'Cat1Start3', 'Cat1Start4', 'Cat1ScrollY', ];
const navsJ = ['カテ１ホーム', 'カウントダウン', 'スタート', 'スタート２', 'スタート３', 'スタート４', 'カテ１すく１', ];

const Cat1Navbar = () => {

  const [active, setActive] = useState(navs[0]);

  return (
    <>
      {navs.map((nav, index) => {
        return (
          <span key={index}>
            <NavLink to={nav}>
              <StyledButton key={nav}
                active={active === nav ? +true : +false }
                onClick={() => setActive(nav)}
              >{navsJ[index]}</StyledButton>
            </NavLink>
          </span>
        )}
      )}
    </>
  )
}

export default Cat1Navbar
