import PersonalNavbar from "./Personal/PersonalNavbar";
import PersonalMain from "./Personal/PersonalMain";

const Personal = () => {
  return (
    <>
      <div className="PersonalNavbar">
        <PersonalNavbar />
      </div>
      <div className="PersonalMain">
        <PersonalMain />
      </div>
    </>
  )
}
export default Personal