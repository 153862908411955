import { useEffect, useState, } from "react";
import { motion, useAnimationControls, animate, useMotionValue, useTransform, } from "framer-motion";

import amazonLogoRgb from "../assets/images/Amazon-logo-RGB.png";

const Footer = () => {

  return (
    <>
      <div style={{border: "solid 3px #000 " }}>
        <a href="https://www.amazon.co.jp?&linkCode=ll2&tag=pbear-22&linkId=484848be60d5d8b540f2f03ae0df9d51&language=ja_JP&ref_=as_li_ss_tl" style={{textDecoration: "none"}}><img src={amazonLogoRgb} width="150" alt="amazonLogoRgb" /></a>
          <svg viewBox="0, 0, 300, 50" style={{justifyContent: "center", alignItems: "center", textDecoration: "none"}}>
            <text x="150" y="30" fontSize="25" stroke="#000" text-anchor="middle" strokeWidth="0.5" style={{justifyContent: "left", alignItems: "center", border: "solid 2px #000", textDecoration: "none"}}>
              <a href='https://www.amazon.co.jp?&linkCode=ll2&tag=pbear-22&linkId=484848be60d5d8b540f2f03ae0df9d51&language=ja_JP&ref_=as_li_ss_tl'>
                タイムセール
              </a>
            </text>
          {/* <path d="M 0 0, L 100 0, L 100 5, L 0 5, Z" fill="#000" stroke="#66ff33" />
          <div className="absolute"><a href='https://amzn.to/3wECFNL'>[Amazonタイムセール]</a></div> */}
          {/* <path d="M 1,1 L 50,5" stroke="#0000ff" /> */}
          {/* <path d="M 0 0, L 10 50, L 100 0, L 15 10, Z" fill="#009ce1" stroke="#c98d8d" /> */}
        </svg>
      </div>
    </>
  )
}

export default Footer;
