import { motion, useAnimationControls } from "framer-motion";
import { useEffect, useState } from "react";
import AboutTimer from "./AboutTimer";

const AboutScroll3 = () => {

  const [display, setDisplay] = useState(true);
  const controls = useAnimationControls();

  const handleAnimate = async() => {
    await controls.start({
      x: 200,
      transition: { scale: 2, duration: 0.5 },
    });
    await controls.start({
      y: 200,
      className: 'figure002',
      // background: "#f00",
      transition: { scale: 2, duration: 0.5 },
    });
    await controls.start({
      className: 'figure002',
    });
  }

  useEffect(() => {
    // controls.start({
    //   x: 200,
    //   // background: "#f00",
    //   transition: { scale: 2, duration: 0.5 },
    // });
  }, [])

  return (
    <>
      <motion.div 
        id='figure001' 
        className="figure001"
        animate={controls} />
      {/* <ul>
        <motion.li custom={0} animate={controls} />
        <motion.li custom={1} animate={controls} />
        <motion.li custom={2} animate={controls} />
      </ul> */}
      <button
        // onClick={() => handleAnimate()}
        onClick={() => {
          setDisplay(!display);
          handleAnimate();}
        }
        // className="mt-4 rounded-md bg-slate-900 px-4 py-2 font-medium">
        className="aboutbutton">
        Trigger animation
      </button>
      { display && <AboutTimer /> }
    </>
  )
}

export default AboutScroll3
