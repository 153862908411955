import "./App.css";
import { useState, useReducer, createContext } from "react";
import { BrowserRouter } from "react-router-dom";
import { useCookies } from "react-cookie";

import Header from "./components/Header";
import Navbar from "./components/Navbar";
import Main from "./components/Main";
import Footer from "./components/Footer";

export const AppContext = createContext();

const initialState = 0;
const reducer = (currentState, action) => {
  // debugger;
  switch (action) {
    case "add_1":
      return currentState + 1;
    case "add_1000":
      return currentState + 1000;
    case "multiple_3":
      return currentState * 3;
    case "reset":
      return initialState;
    default:
      return currentState;
  }
};

function App() {
  // 履歴表示用抽選数字cookie
  const [
    cookiesHistoriesSelectedNumber,
    setCookieHistoriesSelectedNumber,
    removeCookieHistoriesSelectedNumber,
  ] = useCookies("historiesSelectedNumber");

  // 履歴表示用最大数字cookie
  const [
    cookiesHistoriesMaxNumber,
    setCookieHistoriesMaxNumber,
    removeCookieHistoriesMaxNumber,
  ] = useCookies("historiesMaxNumber");

  const [cookies, setCookie, removeCookie] = useCookies([
    "title",
    "rouletteSound",
    "maxNumber",
    "item01",
    "item02",
    "item03",
    "item04",
    "item05",
    "item06",
    "item07",
    "item08",
    "item09",
    "item10",
    "directionItIsTurning",
    "startPosition",
    "displayList",
  ]);

  // const [cookies, setCookie, removeCookie] = useCookies(["gMaxNumber"]);
  // const [gMaxNumber, setGMaxNumber] = useState(cookies.maxNumber);

  const [
    gHistoriesSelectedNumberArrayState,
    setGHistoriesSelectedNumberArrayState,
  ] = useState([]);

  const [gHistoriesMaxNumberArrayState, setGHistoriesMaxNumberArrayState] =
    useState([]);

  // let flag = 0;
  //
  // const displayHistories = gHistoriesArrayState.map((history, index) => {
  //   if (flag === 1) {
  //     // flagが1なら今回の数字（1）は抽選した数字ではないので、flagを0に戻してスキップ
  //     flag = 0;
  //     return;
  //   } else if (history === 0) {
  //     // 抽選数字が0なら抽選した数字ではない、且つ、次の数字（1）も抽選した数字ではない（初期値）ので、次の数字をスキップさせるためにflagを1に設定
  //     flag = 1;
  //     return;
  //   } else {
  //     setCookie("histories", JSON.stringify(gHistoriesArrayState));

  //     // それ以外であれば抽選数字なので、履歴表示
  //     return <>-&gt;【{history}】</>;
  //   }
  // });

  const displayHistoriesSelectedNumber = gHistoriesSelectedNumberArrayState.map(
    (history, index) => {
      setCookieHistoriesSelectedNumber(
        "historiesSelectedNumber",
        JSON.stringify(gHistoriesSelectedNumberArrayState)
      );
      return (
        <>
          【
          {
            gHistoriesSelectedNumberArrayState[
              gHistoriesSelectedNumberArrayState.length - index - 1
            ]
          }
          /
          {
            gHistoriesMaxNumberArrayState[
              gHistoriesSelectedNumberArrayState.length - index - 1
            ]
          }
          】&lt;-
        </>
      );
    }
  );

  const displayHistoriesMaxNumber = gHistoriesMaxNumberArrayState.map(
    (historyMaxNumber, index) => {
      setCookieHistoriesMaxNumber(
        "historiesMaxNumber",
        JSON.stringify(gHistoriesMaxNumberArrayState)
      );
      return <>-&gt;【{historyMaxNumber}】</>;
    }
  );

  const [gValue, gDispatch] = useReducer(reducer, initialState);

  return (
    <AppContext.Provider
      value={{
        gHistoriesSelectedNumberArrayState,
        setGHistoriesSelectedNumberArrayState,
        gHistoriesMaxNumberArrayState,
        setGHistoriesMaxNumberArrayState,
        gValue,
        gDispatch,
        cookies,
        setCookie,
        removeCookie,
      }}
    >
      <BrowserRouter>
        <div className="App">
          <div className="Header">
            <Header />
          </div>
          <div className="Navbar">
            <Navbar />
          </div>
          <div className="Main">
            <Main />
          </div>
        </div>
        {/* ※再読込で履歴はクリアされます
        <div className="variable">履歴：{displayHistoriesSelectedNumber}</div>
        <div className="variable">gValue : {gValue}</div> */}

        <div className="Footer">
          <Footer />
        </div>
      </BrowserRouter>
      <div style={{ textAlign: "center" }}>
        <a href="mailto:btime@btime.online">btime@btime.online</a>
      </div>
    </AppContext.Provider>
  );
}
export default App;
