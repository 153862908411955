import { motion } from 'framer-motion';

const BoxContact = () => {
  return (
    <motion.div className='home container'
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ delay: 0.2, duration: 1.5 }}
    >
    <h3 className="ui header">ボックス連絡</h3>
      <p>ボックス連絡</p>
    </motion.div>
  );
};
export default BoxContact;
