import { motion } from 'framer-motion';

const Contact = () => {
    return (
      <motion.div className='home container'
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 0.2, duration: 1.5 }}
      >
          <h3 className="ui header">Contact</h3>
        <p>Contact</p>
      </motion.div>
    );
  };
  export default Contact;
  