import { Routes, Route, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

import Cat1Home from "./Cat1Home";
import Cat1Countdown from "./Cat1Countdown";
import Cat1Start from "./Cat1Start";
import Cat1Start2 from "./Cat1Start2";
import Cat1Start3 from "./Cat1Start3";
import Cat1Start4 from "./Cat1Start4";
import Cat1ScrollY from "./Cat1ScrollY";

const Cat1Main = () => {
  const location = useLocation();
  return (
    <>
      <AnimatePresence>
        <Routes location={location} key={Location.pathname}>
          <Route element={<Cat1Home />} exact path="/" />
          <Route element={<Cat1Home />} path="/cat1home" />
          <Route element={<Cat1Countdown />} path="/cat1countdown" />
          <Route element={<Cat1Start />} path="/cat1start" />
          <Route element={<Cat1Start2 />} path="/cat1start2" />
          <Route element={<Cat1Start3 />} path="/cat1start3" />
          <Route element={<Cat1Start4 />} path="/cat1start4" />
          <Route element={<Cat1ScrollY />} path="/cat1scrolly" />
        </Routes>
      </AnimatePresence>
    </>
  )
}
export default Cat1Main
