import { useEffect, useState, } from "react";
import { motion, useAnimationControls, animate, useMotionValue, useTransform, } from "framer-motion";

const Header = () => {

  const divControls = useAnimationControls();
  const [ selectedNumber, setSelectedNumber ] = useState(0);
  const [ displayNumber, setDisplayNumber ] = useState(0);
  const [ nowInRaffle, setNowInRaffle ] = useState(false);

  const count = useMotionValue(0);
  const rounded = useTransform(count, Math.round);

  // 1からtargetCountまでの数字を抽選
  let targetCount = 10;
  // ルーレット回転数
  let roundCount = 2;
  // 止まるまでの時間
  let stopTimer = 2;

  const randomNumberInRange = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  const handleRouletteStart = async() => {

    // 抽選中のため、ボタンを無効にする
    setNowInRaffle(true);

    // 0からスタート
    count.set(0);
    await setSelectedNumber(0);

    // 1～Xの数字をランダムでget
    // 「targetCount * x + 1」は数字がx周後に止まる
    // setSelectedNumber(randomNumberInRange(201, 300));
    setSelectedNumber(randomNumberInRange(targetCount * roundCount + 1, targetCount * (roundCount + 1)));
  }

  animate(count, selectedNumber, {
    duration: stopTimer,
    onUpdate: latest => {
      setDisplayNumber(rounded.get());
    }
  });

  useEffect(() => {

    divControls.start({
      border: '2px solid #ffff00',
      color: '#ffff00',
      // transition: {
      //   duration: 0.5,
      // }
    });

    // 抽選数字になれば、決定音を鳴らして背景色を変更する
    if(selectedNumber === rounded.get()) {
      divControls.start({
        border: '2px solid #00ff00',
        color: '#00ff00',
        // scale: [1.0, 1.0],
        // transition: {
        //   duration: 0.2,
        // },
      });
      // 抽選が終ったため、ボタンを有効にする
      setNowInRaffle(false);
    }
  }, [displayNumber]);

  return (
    <>
      <motion.div className="HeaderTitle"
        initial={{ y: -100 }}
        animate={{ y: 0 }}
        transition={{ delay: 0, type: 'spring', stiffness: 100 }}
      >
        <div>
          <img src="../../favicon.ico" alt="" width="50" height="50" />
        </div>
        &nbsp;ルーレットくん
        <motion.div className="HeaderRouletteCanvas" animate={divControls}>
          { rounded.get() % targetCount + 1 }
          </motion.div>
        <button className="HeaderButton" disabled={nowInRaffle} onClick={() => handleRouletteStart()}>
          抽選
        </button>
      </motion.div>
    </>
  )
}

export default Header;
