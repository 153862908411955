import { NavLink } from 'react-router-dom'
import { useState } from "react";
import styled from "@emotion/styled";

const StyledButton = styled.button`
  color: ${({ active }) => active ? '#cccc00' : 'lightgray'};
  background: ${({ active }) => active ? 'linear-gradient(180deg, #663300, #996633)' : 'linear-gradient(180deg, #100c08, #663300)'};
  ${({ active }) => active && `text-shadow: lightgray 5px 5px 10px; box-shadow: #cccc00 5px 5px 10px;`}
`;

const navs = ['BoxHome', 'BoxAnimate', 'BoxCountdown', 'BoxAbout', 'BoxContact', 'BoxSlot', 'BoxModal', ];
const navsJ = ['ボックスホーム', 'ボックスアニメ', 'カウントダウン', 'BoxAbout', 'Box連絡', 'Boxスロット', 'Boxモーダル', ];

const BoxNavbar = () => {

  const [active, setActive] = useState(navs[0]);

  return (
    <>
      {navs.map((nav, index) => {
        return (
          <span key={index}>
            <NavLink to={nav}>
              <StyledButton key={nav}
                active={active === nav ? +true : +false }
                onClick={() => setActive(nav)}
              >{navsJ[index]}</StyledButton>
            </NavLink>
          </span>
        )}
      )}
    </>
  )
}

export default BoxNavbar
