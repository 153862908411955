import { Routes, Route, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

import SampleanimeHome from "./SampleanimeHome";
import SampleanimeAnimate from "./SampleAnimate";
import SampleanimeCountdown from "./SampleanimeCountdown";
import SampleanimeAbout from "./SampleanimeAbout";
import SampleanimeContact from "./SampleanimeContact";
import SampleanimeSlot from "./SampleanimeSlot";

import './Sampleanime.css';

const SampleanimeMain = () => {
  const location = useLocation();

  return (
    <>
      <AnimatePresence>
        <Routes location={location} key={Location.pathname}>
          <Route element={<SampleanimeHome />} exact path="/" />
          <Route element={<SampleanimeAnimate />} path="/sampleanimeanimate" />
          <Route element={<SampleanimeAbout />} path="/sampleanimeabout" />
          <Route element={<SampleanimeCountdown />} path="/sampleanimecountdown" />
          <Route element={<SampleanimeContact />} path="/sampleanimecontact" />
          <Route element={<SampleanimeSlot />} path="/sampleanimeslot" />
        </Routes>
      </AnimatePresence>
    </>
  )
}
export default SampleanimeMain
