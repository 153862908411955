import { useState } from "react";
import './BoxModal.css';
// import { Modal } from '../Modal/Modal';
import Modal from '../Modal/Modal';
import { motion, animate, useMotionValue, useTransform, useScroll } from "framer-motion";


const BoxModal = ({children}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const handleButtonClick = () => {
    setModalOpen(false);
  }

  const handleButtonSubmit = () => {
    setModalOpen(false);
    alert('aa')
  }


  return (
    <>
      <motion.div className='home container'
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.2, duration: 1.5 }}
      >
      <h3 className="ui header">モーションモーション</h3>
      <p>ボックス連絡</p>
      </motion.div>

      <button className="btn btn-open" onClick={() => setModalOpen(true)}>Open</button>
        {modalOpen && (
          <Modal
            onSubmit={handleButtonSubmit}
            onCancel={handleButtonClick}
            onClose={handleButtonClick}
          >
            <h1>This is the modal tiiiitle</h1>
            <p>This is the modal description</p>
          </Modal>
        )}
    </>
  )
}
export default BoxModal
