import { useContext } from "react";
import { AppContext } from "../../App"

const AboutContext = () => {
  const { gValue, gDispatch } = useContext(AppContext);

  return (
    <>
        <div>AboutContextこっちはApp.jsのレベルで変数を持っているから、どの画面に行っても初期化されない</div>
        <button onClick={() => gDispatch('add_1')}>Add + 1</button>
        <button onClick={() => gDispatch('add_1000')}>Add + 1000</button>
        <button onClick={() => gDispatch('multiple_3')}>Multiple * 3</button>
        <button onClick={() => gDispatch('reset')}>Reset</button>

        gValue:{ gValue }
    </>
  )
}

export default AboutContext
