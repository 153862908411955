import { Routes, Route, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

import SampleHome from "./SampleHome";
import SampleAnimate from "./SampleAnimate";
import SampleCountdown from "./SampleCountdown";
import SampleAbout from "./SampleAbout";
import SampleContact from "./SampleContact";
import SampleSlot from "./SampleSlot";
import SampleScroll from "./SampleScroll";

import './Sample.css';

const SampleMain = () => {
  const location = useLocation();

  return (
    <>
      <AnimatePresence>
        <Routes location={location} key={Location.pathname}>
          <Route element={<SampleHome />} exact path="/" />
          <Route element={<SampleAnimate />} path="/sampleanimate" />
          <Route element={<SampleAbout />} path="/sampleabout" />
          <Route element={<SampleCountdown />} path="/samplecountdown" />
          <Route element={<SampleContact />} path="/samplecontact" />
          <Route element={<SampleSlot />} path="/sampleslot" />
          <Route element={<SampleScroll />} path="/samplescroll" />
        </Routes>
      </AnimatePresence>
    </>
  )
}
export default SampleMain
