// import HowToUseAttension from "../HowToUseAttension"

const HowToUseAttension = () => {
  return (
    <div style={{padding: '10px', margin: '0 auto', width: '330px', backgroundColor: '#ff99ff', color: '#000000', textAlign: 'left',}}>
      <ul>
        <li style={{listStyleType: 'disc',}}>法律、条例に反する行為は禁止です</li>
        <li style={{listStyleType: 'disc',}}>個人利用、商用利用に関わらず利用はフリーです</li>
        <li style={{listStyleType: 'disc',}}>リンク、その他紹介については自由に行ってください</li>
        <li style={{listStyleType: 'disc',}}>本アプリ利用により生じた損害について、一切の責任は負いません</li>
        <li style={{listStyleType: 'disc',}}>配信利用する場合は「配信利用」もご覧ください</li>
      </ul>
    </div>
  )
}
export default HowToUseAttension