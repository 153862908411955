import { motion } from 'framer-motion';

const BoxAbout = () => {
  return (
    <motion.div className='home container'
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ delay: 0.2, duration: 1.5 }}
    >
    <h3 className="ui header">About</h3>
      <p>BoxAbout</p>
    </motion.div>
  );
};
export default BoxAbout;
