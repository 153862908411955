import { Routes, Route, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

import PersonalHome from "./PersonalHome";
import TypeNormal from "../Type/Normal"

const PersonalMain = () => {
  const location = useLocation();
  return (
    <>
      <AnimatePresence>
        <Routes location={location} key={Location.pathname}>
          <Route element={<PersonalHome />} exact path="/" />
          <Route element={<PersonalHome />} path="/personalhome" />
          <Route element={<TypeNormal />} path="/typenormal" />
        </Routes>
      </AnimatePresence>
    </>
  )
}
export default PersonalMain
