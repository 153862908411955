import { motion, useAnimationControls, animate, useMotionValue, useTransform, useScroll } from "framer-motion";
import { useEffect, useState, useRef } from "react";

const Cat1Start2 = () => {

  const divControls = useAnimationControls();
  const [ selectedNumber, setSelectedNumber ] = useState(0);
  const [ nowInRaffle, setNowInRaffle ] = useState(false);

  const count = useMotionValue(0);
  const rounded = useTransform(count, Math.round);

  const randomNumberInRange = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  const handleRouletteStart = async() => {
    // setNowInRaffle(true);
    // console.log('nowInRaffle:', nowInRaffle)

    // 0からスタート
    count.set(0);
    await setSelectedNumber(0);
    // setSelectedNumber(0);

    // 1～Xの数字をランダムでget
    setSelectedNumber(randomNumberInRange(101, 101));
  }

  const modSelectedNumber = selectedNumber % 100;

  animate(count, selectedNumber, {
    duration: 1,
    onUpdate: latest => console.log(' selectedNumber:', selectedNumber, ' rounded:', rounded.get(), ' count:', count.get(),
    ' nowInRaffle:', nowInRaffle,
    ' latest:', latest ),
  });

  useEffect(() => {
    divControls.start({
      // x: ['0px', '1000px', '1000px', '-1000px', '-1000px', '0px'],
      // y: ['0px', '0px', '-1200px', '-1200px', '0px', '0px'],
      backgroundColor: ['#00f', '#fff', '#0f0', ],
      transition: {
        duration: 2,
      },
    });
  // }, [selectedNumber, nowInRaffle]);
  }, [selectedNumber]);

  return (
    <>
      <div>
        <div id='target' />
          <button disabled={nowInRaffle} onClick={() => handleRouletteStart()}>
            ルーレットスタート2
          </button>
        </div>
      <motion.h1
        // initial={false}
        className='container'
        animate={divControls}
        onAnimationComplete={
          async definition => {
            console.log('Completed animating', definition);
            // await setNowInRaffle(false);
          }
        }
      >
          { rounded }
      </motion.h1>
      <motion.h1 className='home container'>
          { count }
      </motion.h1>
      { modSelectedNumber }
    </>
  )
}
export default Cat1Start2
