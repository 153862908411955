import Cat1Navbar from "./Cat1/Cat1Navbar";
import Cat1Main from "./Cat1/Cat1Main";

const Cat1 = () => {
  return (
    <>
      <div className="Cat1Navbar">
        <Cat1Navbar />
      </div>
      <div className="Cat1Main">
        <Cat1Main />
      </div>
    </>
  )
}
export default Cat1