import SampleNavbar from "./Sample/SampleNavbar";
import SampleMain from "./Sample/SampleMain";

const Sample = () => {
  return (
    <>
      <div className="SampleNavbar">
        <SampleNavbar />
      </div>
      <div className="SampleMain">
        <SampleMain />
      </div>
    </>
  )
}
export default Sample
