import { motion, useAnimationControls, animate, useMotionValue, useTransform, } from "framer-motion";
import { useEffect, useState, } from "react";
import useSound from "use-sound";

import start from '../../assets/sounds/inRaffle.mp3';
import game from '../../assets/sounds/stop.mp3';

const Cat1Start3 = () => {

  const [playStart] = useSound(start);
  const [playGame] = useSound(game);

  const divControls = useAnimationControls();
  const [ selectedNumber, setSelectedNumber ] = useState(0);
  const [ displayNumber, setDisplayNumber ] = useState(0);
  const [ nowInRaffle, setNowInRaffle ] = useState(false);

  const count = useMotionValue(0);
  const rounded = useTransform(count, Math.round);

  // 1からtargetCountまでの数字を抽選
  let targetCount = 100;

  const randomNumberInRange = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  const handleRouletteStart = async() => {

    // 抽選中のため、ボタンを無効にする
    setNowInRaffle(true);

    // 0からスタート
    count.set(0);
    await setSelectedNumber(0);

    // 1～Xの数字をランダムでget
    // setSelectedNumber(randomNumberInRange(201, 300));
    setSelectedNumber(randomNumberInRange(targetCount * 2 + 1, targetCount * 3));
  }

  const modSelectedNumber = selectedNumber % targetCount + 1;

  animate(count, selectedNumber, {
    duration: 2,
    onUpdate: latest => {
      // console.log(
      //   ' selectedNumber:', selectedNumber, ' rounded:', rounded.get(), ' count:', count.get(),
      //   ' nowInRaffle:', nowInRaffle,
      //   ' latest:', latest );
      // loopCounter += 1;
      setDisplayNumber(rounded.get());
    }
  });

  useEffect(() => {
    divControls.start({
      backgroundColor: ['#00f', '#000', ],
      color: '#fff',
      transition: {
        duration: 0.5,
      },
    });

    playStart();

    console.log(
      ' selectedNumber:', selectedNumber,
      ' rounded:', rounded.get(),
    );

    // 抽選数字になれば、決定音を鳴らして背景色を変更する
    if(selectedNumber === rounded.get()) {
      playGame();
      divControls.start({
        backgroundColor: '#f00',
        color: '#fff',
        transition: {
          duration: 0,
        },
      });
      // 抽選が終ったため、ボタンを有効にする
      setNowInRaffle(false);
    }
  }, [displayNumber]);

  return (
    <>
      <div>
        <div id='target' />
          <button disabled={nowInRaffle} onClick={() => handleRouletteStart()}>
            ルーレットスタート2
          </button>
        </div>
      <motion.h1
        // initial={false}
        className='container'
        animate={divControls}
        onAnimationComplete={
          async definition => {
            console.log('Completed animating', definition);
          }
        }
        >
          { rounded.get() % targetCount + 1 }
      </motion.h1>
      {/* <motion.h1 className='home container'>
          { count }
      </motion.h1> */}
      { modSelectedNumber }
    </>
  )
}
export default Cat1Start3
