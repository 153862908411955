import { Routes, Route, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

import './About.css';

import AboutHome from "./AboutHome";
import AboutScroll1 from "./AboutScroll1";
import AboutScroll2 from "./AboutScroll2";
import AboutScroll3 from "./AboutScroll3";
import AboutScroll4 from "./AboutScroll4";
import AboutContext from "./AboutContext";
import AboutReducer from "./AboutReducer";
import AboutValue from "./AboutValue";

const AboutMain = () => {
  const location = useLocation();

  return (
    <>
      <AnimatePresence>
        <Routes location={location} key={Location.pathname}>
          <Route element={<AboutHome />} path="/" />
          <Route element={<AboutScroll1 />} path="/aboutscroll1" />
          <Route element={<AboutScroll2 />} path="/aboutscroll2" />
          <Route element={<AboutScroll3 />} path="/aboutscroll3" />
          <Route element={<AboutScroll4 />} path="/aboutscroll4" />
          <Route element={<AboutContext />} path="/aboutcontext" />
          <Route element={<AboutReducer />} path="/aboutreducer" />
          <Route element={<AboutValue />} path="/aboutvalue" />
        </Routes>
      </AnimatePresence>
    </>
  )
}

export default AboutMain;
