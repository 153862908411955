import { motion, animate, useMotionValue, useTransform, useScroll } from "framer-motion";
import { useEffect } from "react";
// import { AnimatePresence } from "framer-motion";
// import { useState } from "react";

const variants = {
  open: { opacity: 1, x: 0 },
  closed: { opacity: 0, x: "-100%" },
}

const BoxSlot = () => {
  const count = useMotionValue(0);

  // let abc = false;

  let selectedNumber = 0;
  const rounded = useTransform(count, Math.round);

  const randomNumberInRange = (min, max) => {
    // 👇️ get number between min (inclusive) and max (inclusive)

    // { selectedNumber === count ?
    //    abc = true
    //   :
    //    abc = false
    // }
    
    // return abc;
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  useEffect(() => {
    const animation = animate(count, selectedNumber = randomNumberInRange(1, 100), {
      duration: 3,
      // onUpdate: latest => console.log('abc:', abc ,'selectedNumber:', selectedNumber, ' rounded:', rounded, ' count:', count.get(), ' latest:', latest),
      onUpdate: latest => console.log(' selectedNumber:', selectedNumber, ' rounded:', rounded, ' count:', count.get(), ' latest:', latest),
    });

    return animation.stop;
  }, []);
  
  return (
    <motion.h1 className='home container'
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ delay: 0.2, duration: 1.5 }}
    >
    {/* <h3 className="ui header">About</h3> */}
      {/* <p>BoxAbout</p> */}
      { rounded }
    </motion.h1>
  )
}
export default BoxSlot