import { useContext } from "react";
import { AboutContext } from "./AboutReducer";

const AboutReducerCanvas = () => {
  const { lValue, lDispatch } = useContext(AboutContext);

  return (
    <>
      <div>AboutReducerCanvas</div>
      <div>AboutContext</div>
      <button onClick={() => lDispatch('add_1')}>Add + 1</button>
      <button onClick={() => lDispatch('multiple_3')}>Multiple * 3</button>
      <button onClick={() => lDispatch('reset')}>Reset</button>

      lValue:{ lValue }
    </>
  )
}

export default AboutReducerCanvas
