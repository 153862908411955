const HowToUseDistribution = () => {
  return (
    <div style={{padding: '10px', margin: '0 auto', width: '330px', backgroundColor: '#ff99ff', color: '#000000', textAlign: 'left',}}>
      <ul>
        <li style={{listStyleType: 'disc',}}>配信で利用する場合、ルーレットくんの画面は自由に配信してください</li>
        <li style={{listStyleType: 'disc',}}>配信メディアの規約に反する利用は行わないでください</li>
      </ul>
    </div>
  )
}
export default HowToUseDistribution