import BoxNavbar from "./Box/BoxNavbar";
import BoxMain from "./Box/BoxMain";

const Box = () => {
  // const location = useLocation();
  return (
    <>
      <div className="BoxNavbar">
        <BoxNavbar />
      </div>
      <div className="BoxMain">
        <BoxMain />
      </div>
    </>
  )
}
export default Box
