import { motion, useAnimationControls } from "framer-motion";
import { useEffect, useState } from "react";

const AboutRouletteCanvas = () => {
  const [buttonDisabled, setButtonDisabled] = useState(0);
  const controls = useAnimationControls();

  const time = async() => {
    setButtonDisabled(prevCount => prevCount + 1);
    await controls.start({
      x: 200,
      transition: { scale: 2, duration: 3 },
    });
  }

  useEffect(() => {
    const interval = setInterval(time, 1000);
    return () => {
      clearInterval(interval);
      console.log('cleared');
    }
  }, [])

  return (
    <>
    <div>{ buttonDisabled }</div>
      <motion.div 
      id='figure001' 
      className="figure001"
      animate={controls}
      />
      { buttonDisabled }
    </>

  )
}

export default AboutRouletteCanvas
