import { useAnimate } from "framer-motion";



const AboutScroll2 = () => {
  const [scope, animate] = useAnimate();

  const handleAnimate = async() => {
    // animate(scope.current, { opacity: 0 })
    // animate('#target', { opacity: 0 })
    await animate('#target', { x: 150 });
    await animate('#target', { y: 150, rotate: '360deg' }, { duration: 0.5 });
    await animate('#target', { background: 'green' });
  }
  return (
    <>
      <div>AboutScroll2</div>
      <div ref={scope}>
        <div id='target' className="aboutanimate" />
        <button
          onClick={() => handleAnimate()}
          // className="mt-4 rounded-md bg-slate-900 px-4 py-2 font-medium">
          className="aboutbutton">
          Trigger animation
        </button>
      </div>
    </>
  )
}

export default AboutScroll2
