import { Link } from "react-router-dom";

const Roulette = () => {
  return (
    <>
      <div className='container'>
        <h2>ルーレット</h2>
        <Link to="/spin">
          <button>
            スピン
          </button>
        </Link>
        <Link to="/spin2">
          <button>
            スピン2
          </button>
        </Link>
        <Link to="/spin3">
          <button>
            スピン3
          </button>
        </Link>
        <Link to="/spin4">
          <button>
            スピン4
          </button>
        </Link>
        <Link to="/spin5">
          <button>
            スピン5
          </button>
        </Link>
        <Link to="/spin6">
          <button>
            スピン6
          </button>
        </Link>
      </div>
    </>
  );
};
export default Roulette;
