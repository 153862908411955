import { Routes, Route, useLocation } from "react-router-dom";

// import Home from "./Home";
import Default from "./Default";
import Personal from "./Personal";
import Home from "./Home";
import Sample from "./Sample";
import Sampleanime from "./Sampleanime";
import Cat1 from "./Cat1";
import About from "./About";
import Contact from "./Contact";
import Roulette from "./Roulette";
import Spin from "./Spin/Spin";
import Settings from "./Settings";
import Slot from "./Slot";
import Box from "./Box";
import HowToUse from "./HowToUse";

const Main = () => {
  const location = useLocation();
  return (
    <>
      <Routes location={location} key={Location.pathname}>
        {/* <Route element={<Home />} exact path="/" /> */}
        <Route element={<Spin />} exact path="/" />
        <Route element={<Personal />} path="/personal/*" />
        <Route element={<Box />} path="/box/*" />
        <Route element={<Home />} path="/home/*" />
        <Route element={<Sample />} path="/sample/*" />
        <Route element={<Sampleanime />} path="/sampleanime/*" />
        <Route element={<Cat1 />} path="/cat1/*" />
        <Route element={<About />} path="/about/*" />
        <Route element={<Contact />} path="/contact" />
        <Route element={<Roulette />} path="/roulette" />
        <Route element={<Settings />} path="/settings/*" />
        <Route element={<Spin />} path="/spin/*" />
        <Route element={<Slot />} path="/slot" />
        <Route element={<HowToUse />} path="/howtouse/*" />
      </Routes>
    </>
  )
}
export default Main
